import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import {Link} from 'react-router-dom';
import {fetchAccountListings,toggleActive, deleteListing} from '../actions';
import { useState } from "react";
import "./MyListings.css"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dog from './Images/dog1.png'
import { useHistory } from "react-router";

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(130,190,170)',
    },
  },
  typography: {

    button: { // Here is where you can customise the button
      fontWeight: 900,
    },    
  },  
});

const MyListings = ({accountListings,fetchAccountListings,toggleActive, auth}) => {
    const [listings, setListings] = useState([]);
    const [open, setOpen] = useState(false);
    const [delObj, setDelObj] = useState({});
    const [active, setActive] = useState(true);
    const [loggedin, setLoggedin] = useState(false);

    const history = useHistory()
    // useEffect(()=>{
        
    // },[])

    useEffect(()=>{
        // console.log(auth)
        if (auth) {
            setLoggedin(true)
            fetchAccountListings()
        } else {
            setLoggedin(false)
        }
    },[auth])

    const toggleSwitch = async (item) => {
        // console.log(item)
        const x = await toggleActive(item)
        fetchAccountListings()
        setActive(!active)
    }

    const deleteThisListing = (item) => {
        // deleteListing(item)
        // fetchAccountListings()
    }

    const deleteThis = () => {
        deleteListing(delObj)
        fetchAccountListings()
        handleClose()
        history.go(0)
    }

    const cancelDelete = () => {
        handleClose()
    }

    const handleOpen = (item) => {
        setDelObj(item)
        setOpen(true)
      };
      const handleClose = () => setOpen(false);
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    useEffect(()=>{
        
        // console.log(accountListings.length)
        // console.log(accountListings)
        if (accountListings.length > 0) {
            const tmp = accountListings.map((item,index) => {
                return (
                    <div key={item._id} style={{padding: '10px'}}>
                        
                        
                        <div>
                            <div className="card sticky-action medium" style={{width: '280px', height: '500px', overflow: 'visible'}}>
                            
                            <div className="card-image" style={{overflow: 'visible'}}>
                                    {/* <img className="" src={item.photo.replace('1000/','300/')} /> */}
                                    {item.photo.search('undefined.') < 0 ? <img src={item.photo.replace('1000/','300/')} alt="location thumbnail"/> : null}
                            </div>
                                
                                <div className="card-content">
                                    <span className="card-title grey-text text-darken-4" style={{textTransform: 'capitalize'}}>{item.title}</span>
                                    <div style={{paddingTop: '12px',display: 'flex',justifyContent: 'space-around'}}>
                                    <Link className="btn-floating activator" style={{backgroundColor: 'rgb(130,190,170)'}} to='#'><i className="material-icons">menu</i></Link>
                                    <Link className="btn-floating" style={{backgroundColor: 'rgb(130,190,170)'}} to={{ pathname: "/editlisting", state: item }}><i className="material-icons">edit</i></Link>
                                    {/* <a className="btn-floating red" href='#' onClick={()=>deleteThisListing(item)}><i className="material-icons">delete</i></a> */}
                                    <a className="btn-floating red" href='#' onClick={()=>handleOpen(item)}><i className="material-icons">delete</i></a>
                                    

                                    </div>
                                </div>
                                <div className="card-action">
                                <div className="switch center-align">
                                <label>
                                        Inactive
                                        <input className={`${active}`} checked={item.active} onChange={()=>toggleSwitch(item)} type="checkbox" />
                                        <span trigger={`${active}`} className="lever"></span>
                                        Active
                                    </label>
                                </div>
                                </div>
                                <div className="card-reveal">
                                    <span className="card-title grey-text text-darken-4" style={{textTransform: 'capitalize'}}>{item.title}<i className="material-icons right">close</i></span>
                                    <p style={{textTransform: 'capitalize'}}>{item.address}</p>
                                    <p>{item.description ? item.description.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase()): ""}</p>
                                    <p>Looking for a {item.termlength} {item.termlengthfrequency} commitment. Rent is ${item.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} to be paid every {item.termpayment} {item.termpaymentfrequency}.</p>
                                    <p>Deposit: ${item.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                </div>
                            </div>
                        </div>
            

                    </div>
                )
            })
            tmp.push((
                <Button key="addnew" variant="outlined" color="primary" component={Link} to={'/newlisting'} style={{width: '280px', height: '500px', padding: '10px',marginTop: '17.25px', marginBottom: '14.5px',display: "block", textAlign: "center"}}>
                    <Typography style={{marginTop: '50%'}}>
                        Add New Listing
                    </Typography>
                    <i className="material-icons">add</i>
                </Button>
            ))
            setListings(tmp)
        } else {
            const tmp = []
            tmp.push((
                <Button key="addnew" variant="outlined" color="primary" component={Link} to={'/newlisting'} style={{width: '280px', height: '400px', padding: '10px',marginTop: '17.25px', marginBottom: '14.5px',display: "block", textAlign: "center"}}>
                    <Typography style={{marginTop: '50%'}}>
                        Add New Listing
                    </Typography>
                    <i className="material-icons">add</i>
                </Button>
            ))
            setListings(tmp)
            // setListings([])
        }
        
    },[accountListings])

    const renderedData = listings
      
        // (
        //       <div className="row">
        //         <div className="col xl3">
        //           <div className="card sticky-action" style={{overflow: "visible"}}>
        //             <div className="card-image waves-effect waves-block waves-light">
        //                 <img className="activator" src="images/office.jpg" />
        //             </div>
        //             <div className="card-content">
        //                 <span className="card-title activator grey-text text-darken-4">Card Title<i className="material-icons right">more_vert</i></span>

        //                 <p><a href="#!">This is a link</a></p>
        //             </div>

        //             <div className="card-action">
        //                 <a href="#">This is a link</a>
        //                 <a href="#">This is a link</a>
        //             </div>

        //             <div className="card-reveal" style={{display: "none", transform: "translateY(0%)"}}>
        //                 <span className="card-title grey-text text-darken-4">Card Title<i className="material-icons right">close</i></span>
        //                 <p>Here is some more information about this product that is only revealed once clicked on.</p>
        //             </div>
        //           </div>
        //           </div>
        //       </div>
        // )
    

    
        return (
            
            <div>
                { loggedin ?
                <div>
                <ThemeProvider theme={theme}>
                {/* <div className='right-align' style={{marginBottom: '20px'}}>
                    <Link to='/newlisting' className="btn-flat white-text" style={{backgroundColor: 'rgb(130,190,170)', textTransform: 'capitalize'}}>New Listing<i className="material-icons right">add</i></Link>
                </div> */}
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {renderedData}
                </div>
                
                <div>
                    <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >
                    <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are you sure you want to delete this listing?
                    </Typography>
                    <div style={{paddingTop: '15px'}}>
                    <Button variant='contained' onClick={()=>cancelDelete()}>No</Button>
                    <Button variant='contained' onClick={()=>deleteThis()} style={{float: 'right'}}>Yes</Button>
                    </div>
                    </Box>
                    </Modal>
                </div>
                </ThemeProvider>
                <div style={{height: '30vh'}}>
                      </div>
                      </div>
                : 
                <div>
                    <div style={{height: '20vh'}}></div>
                    <div className='row'>
                        <div className="col" style={{marginLeft: 0, paddingLeft: 0}}>
                            <img style={{width: '203px'}} src={dog}></img>
                        </div>
                        <div className="yours messages col" style={{marginBottom: '20px', width: '700px'}} >
                            <div className="message last">
                                <label style={{fontSize: '70px', fontFamily: 'Alatsi',color: 'rgb(130,190,170)', WebkitTextStroke: '1px black'}}>Please Log In</label>
                            </div>
                        </div>
                    </div> 
                    <div style={{height: '30vh'}}></div>
                </div>
                }
            </div>
            
        )

}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            accountListings: state.accountListings
           }
}

export default withRouter(connect(mapStateToProps,{fetchAccountListings, deleteListing,toggleActive})(MyListings))
