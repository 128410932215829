import React, {Component} from 'react';
import ListingForm from './ListingForm';
import {connect} from 'react-redux';
import { submitListing } from '../../actions';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from "react-router";
import { useEffect } from 'react';
import { useState } from 'react';
import '../Home.css'
import dog from '../Images/dog1.png'

const ListingNew = ({submitListing,auth,formValues}) => {

    const [loggedin, setLoggedin] = useState(false);

    const history = useHistory()

    useEffect(()=>{
        // console.log(auth)
        if (auth) {
            setLoggedin(true)
        } else {
            setLoggedin(false)
        }
    },[auth])

    const handleSubmit = async (values) => {
        await submitListing(values,history)
        // console.log(values)
    }

    {
        return (
            <div>
                {loggedin 
                ?
                <div>
                    <ListingForm onListingSubmit={handleSubmit}/>
                </div>
                :
                <div>
                    <div style={{height: '20vh'}}></div>
                    <div className='row'>
                        <div className="col" style={{marginLeft: 0, paddingLeft: 0}}>
                            <img style={{width: '203px'}} src={dog}></img>
                        </div>
                        <div className="yours messages col" style={{marginBottom: '20px', width: '700px'}} >
                            <div className="message last">
                                <label style={{fontSize: '70px', fontFamily: 'Alatsi',color: 'rgb(130,190,170)', WebkitTextStroke: '1px black'}}>Please Log In</label>
                            </div>
                        </div>
                    </div> 
                    <div style={{height: '30vh'}}></div>
                </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        auth: state.auth,
        formValues: state.form.listingForm,
    }
}

export default connect(mapStateToProps, {submitListing})(withRouter(ListingNew));