

const Signed = () => {

    return(
        <div>
            Thank you for signing.
        </div>
    )
}


export default Signed;