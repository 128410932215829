import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link,withRouter} from 'react-router-dom';
import Select from 'react-select'
import './Header.css'


class Header extends Component {
    state = {
        selectedOption: null
      };
      
    
      handleChange = selectedOption => {
        if (selectedOption.value === 'logout') {
            let logout = document.getElementById("logoutbtn")
            logout.click()
        } 
        if (selectedOption.value === 'mylistings') {
            let logout = document.getElementById("mylistingsbtn")
            logout.click()
        } 
        if (selectedOption.value === 'messages') {
            let msgclk = document.getElementById("mymessagesbtn")
            msgclk.click()
        }
      };



    renderContent() {
        const options = [
            { value: "messages", label: "My Messages"},
            { value: "mylistings", label: "My Listings" },
            { value: "logout", label: "Logout" }
          ];
          const customStyles = {
            control: (provided, state) => ({
              ...provided,
              border: 0,
              boxShadow: 'none',
              width: '130px',
            //   fontFamily: "Gruppo",
              fontSize: "17px",
              margin: "-2px 0px 0px 0px"
            }),
            option: (provided, state) => ({
                ...provided,
                border: 'none',
                // fontFamily: "Gruppo",
                fontSize: "17px"
              }),
              
          };
          const { selectedOption } = this.state;
        switch (this.props.auth) {
            case null:
                return;
            case false:
                return (
                    // <li><a href="auth/google" className="black-text" style={{fontFamily: "Gruppo",fontSize: "17px"}}>Login with Google</a></li>
                    <li>
                        <button className='white' style={{marginTop: '10px'}}>
                  <a href='auth/google' className="white black-text" >
                    <div className="valign-wrapper" style={{padding: '10px'}}>
                        <img  style={{width: '1.5rem', height: '1.5rem' }} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                        <span style={{paddingLeft: '5px',fontFamily: "Roboto"}}>Login with Google</span>
                    </div>
                  </a>
                </button>
                    </li>
                )
            default:
                let headerArr = []
                headerArr.push(<li key="5"><Select  value={selectedOption}
                options={options}
                onChange={this.handleChange}
                isSearchable={false}
                styles={customStyles}
                placeholder={<div className="col black-text right" style={{height: "100%" , fontSize: "17px"}} >Account</div>} 
                // fontFamily="Gruppo"
                blurInputOnSelect={true}
                closeMenuOnSelect={false} menuPortalTarget={document.body} 
                components={{
                    IndicatorSeparator: () => null,
                }}
                /></li>)
                return headerArr
        }
    }

    render() {
        return (
            <nav id="headerID" className="z-depth-0 " style={{width:"100%",position: "relative", left: "0%"}}>
                <div className="nav-wrapper white " style={{padding: "0 0%"}}>

                {
                    this.props.location.pathname!=='/home' && this.props.location.pathname!=='/'? 
                    <Link 
                    to={this.props.auth ? '/home' : '/'} 
                    style={{fontSize: "40px",fontFamily: 'Alatsi',color: 'rgb(130,190,170)', WebkitTextStroke: '1px black'}}
                    className="left brand-logo">
                    Park! Park!
                    </Link>
                    :null
                }

                    
                    
                    
                    <a id="logoutbtn" style={{display: "none"}} className="black-text"  href="api/logout"></a>
                    <a id="mylistingsbtn" style={{display: "none"}} className="black-text"  href="mylistings"></a>
                    <a id="mymessagesbtn" style={{display: "none"}} className="black-text"  href="messages"></a>
                    <ul className="right" style={{overflow: "visible", padding: "0 0%",margin: "0"}}>
                        {this.renderContent()}
                    </ul>
                    
                    
                </div>
            </nav>
        )
    }
}

function mapStateToProps({auth}) {
    return { auth };
}

export default withRouter(connect(mapStateToProps)(Header));