import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import { useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
// const keys = require('../../../config/keys');
import {setKey,setDefaults,setLanguage,setRegion,fromAddress,fromLatLng,fromPlaceId,setLocationType,geocode,RequestType} from 'react-geocode'
import {fetchActiveListings} from '../actions';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser'
import { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import searchIcon from './icons/magnifying-glass.png'
import dog from './Images/dog1.png'
import './Home.css'
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';


const { palette } = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(130,190,170)',
    },
  },
  typography: {

    button: { // Here is where you can customise the button
      fontWeight: 900,
    },    
  },  
});


const Home = ({activeListings,fetchActiveListings}) => {
    const [lat, setLat] = useState(39.7392358);
    const [lng, setLng] = useState(-104.990251);
    const [maplat, setMaplat] = useState(39.7392358);
    const [maplng, setMaplng] = useState(-104.990251)
    const [zipcode, setZipcode] = useState(80202)
    const [searchLocation, setSearchLocation] = useState('Denver')
    const [vismarkers, setVismarkers] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [modalCost, setModalCost] = useState(0)
    const [modalDescription, setModalDescription] = useState('')
    const [modalTermLength, setModalTermLength] = useState('')
    const [modalTermPayment, setModalTermPayment] = useState('')
    const [modalTermPaymentFreq,setModalTermPaymentFreq] = useState('')
    const [modalTermLengthFreq, setModalTermLengthFreq] = useState('')
    const [modalAddress, setModalAddress] = useState('');
    const [modalEmail, setModalEmail] = useState('')
    const [modalPhoto, setModalPhoto] = useState('')
    const [showlabel, setShowlabel] = React.useState("");
    const [searchTerm, setSearchterm] = useState("");


    const position = { lat: lat, lng: lng }; 
    const [open, setOpen] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);

    const cloudName = process.env.REACT_APP_CLOUDNAME
    const history = useHistory();


    const handleChange = event => {
      setShowlabel(event.target.value);
      setSearchterm(event.target.value);
    };

    const removeLabel = event => {
      setShowlabel(' ')
    }

    const clearLabel = event => {
      setShowlabel('')
      event.target.value=''
    }

    const handleSubmit = (event) => {
      event.preventDefault()
    //   setSearchLocation(event.target.searchLocation.value)
    //   event.target.searchLocation.value=''
      history.push({pathname: "/search", state: {item: searchTerm}});
    }





    const renderedData = 
      
        (
              <div>
                <div style={{ padding: '20px'}}>

                  <form onSubmit={handleSubmit} >
                    {/* <input name='searchLocation' placeholder='Find a location...'/> */}
                    <div className={`row ${window.innerWidth > 600 ? 'valign-wrapper' : 'center-align'}`} style={{height: '260px'}}>
                      {/* { window.innerWidth > 600 ? <div className='col m2' style={{margin: 0}}>
                        <label></label>
                      </div> : <div /> } */}
                      <div className="col s12 m2" style={{marginLeft: 0, paddingLeft: 0}}>
                        <img style={{width: '203px'}} src={dog}></img>
                      </div>
                      
                      <div className='col s12 m10' style={{marginLeft: window.innerWidth > 800 ? '20px' : '0px', paddingLeft: window.innerWidth > 800 ? '30px' : '0px'}}>
                        <div className='row'>
                          <div className="yours messages col s12 hide-on-small-only" style={{marginBottom: '20px', marginLeft: '20px'}} >
                            <div className="message last">
                                <label style={{fontSize: '70px', fontFamily: 'Alatsi',color: 'rgb(130,190,170)', WebkitTextStroke: '1px black'}}>Park! Park!</label>
                            </div>
                          </div>
                          <TextField
                            id="searchLocation"
                            // label="Find a location"
                            label={showlabel=== "" ? "Find a location...": ""}
                            InputLabelProps={{shrink: false}}
                            onChange={handleChange}
                            onFocus={removeLabel}
                            onBlur={clearLabel}
                            style={{width: window.innerWidth > 800 ? '80%' : '100%'}}
                            variant='outlined'
                            className='col s9'
                            inputProps={{style: { border: 'none', paddingLeft: '10px', boxShadow: 'none', WebkitBoxShadow: 'none' } }}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">
                              <IconButton
                                aria-label="search for a location"
                                type='submit'
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>,
                            }}

                          />
                          {/* <div className='col s1' style={{margin: 0, padding: 0}}>
                            <ThemeProvider theme={theme}>
                              <Button type='submit' variant="text" startIcon={<img style={{width: '30px', height: '30px'}} src={searchIcon} />}>Search</Button>
                            </ThemeProvider>
                          </div> */}
                        </div>
                        
                      </div>
                      
                    </div>
                  </form>
                </div>
                <div style={{height: '30vh'}}>
                      </div>
              </div>
        )
    

    
        return (
            renderedData
        )
    
}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            form: state.form,
            activeListings: state.activeListings
           }
}

export default withRouter(connect(mapStateToProps,{fetchActiveListings})(Home))
