import React, {useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const items = [
    {title: 'What is ParkPark?', description: 'ParkPark is directory designed to help foodtruck owners find a place to park that will maximize their potential while providing the peace of mind a formal commitment.'},
    {title: 'Who can use ParkPark?', description: 'Anyone can use ParkPark! ParkPark is designed to help connect foodtruck owners with locations available to host a foodtruck. If you have a location that can support a foodtruck, log in and create a new listing! If you own a foodtruck, browse your locality in the map for locations that may work for you. If you find one, log in and contact the listing owner!'},
    {title: 'Why does ParkPark need my Google credentials?', description: 'ParkPark uses your Google name, account ID, and email address to make sure your account stays yours. It allows us to keep your messages and listings associated with only you.'},
    {title: 'Do I have to purchase the contract availabe through ParkPark?', description: 'The contract provided via ParkPark is 100% optional. You do NOT need to purchase it to use the website. Purchasing the contract allows you to handle the signing of the contract digitally via the website. It also allows ParkPark to display how much time is left before the contract expires. However, if you would like to use your own contract, feel free to coordinate with the foodtruck owner separately from the webiste.'},
    {title: 'Does it cost anything to use ParkPark?', description: 'There is no cost to using the website to list a location or to contact the owner of a location you might be interested in. If you would like to purchase the contract shown in the website, this is an optional feature that will allow ParkPark to digitally facilitate the signing of the contract and will keep track of how long the contract has left until it expires.'},
  ]

  return (
    <div>
        <h4 style={{fontFamily: 'Alatsi', textAlign: window.innerWidth > 800 ? 'left' : 'center'}}>Frequently Asked Questions</h4>
        {
            items.map((item,index)=>{
                return (
                    <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography sx={{ width: '100%', flexShrink: 0 }} style={{fontFamily: 'Alatsi'}}>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{item.description}</Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })
        }
        <Accordion expanded={expanded === `panelMA`} onChange={handleChange(`panelMA`)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panelMA-content`} id={`panelMA-header`}>
                <Typography sx={{ width: '100%', flexShrink: 0 }} style={{fontFamily: 'Alatsi'}}>Is there a mobile app?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              ParkPark is a web app. This means there is no separate app available in the Google Play Store or Apple App Store. However, if you would like an app on your mobile phone for easier access:
              <p>If you are using an Android device:</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>1. Open the webpage in the Google Chrome app.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>2. Tap the 3 vertical dots in the top right of the screen to show options.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>3. Tap on 'Add to Home screen' to install the app to your phone.</p>
              <p>If you are using an iOS device:</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>1. Open the webpage in the Safari app.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>2. Tap the 'share' button.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>3. Tap on 'Add to Home screen' to install the app to your phone.</p>
            </AccordionDetails>
        </Accordion>
    </div>
  );
}

export default FAQ