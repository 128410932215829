import React, {Component} from 'react';
import {BrowserRouter,Route} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../actions'
import Header from './Header';
import Landing from './Landing';
import Footer from './Footer';
import TOU from './TOU';
import Cookies from './Cookies';
import Privacy from './Privacy';
import ListingNew from './Listing/ListingNew';
import MyListings from './MyListings';
import EditListingNew from './EditListing/EditListingNew';
import Attributions from './Attributions';
import Home from './Home'
import Messages from './Messages';
import Signed from './Signed';
import ViewContract from './ViewContract';
import FAQ from './FAQ'

class App extends Component {
    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        return (
            <div className="" style={{ position: "relative" }}>
                <BrowserRouter style={{}}>
                    <div className="" style={{ alignItems: "center", minHeight: "100vh",flexDirection: "column"}} >
                        <header style={{width: "100%", top: "0px",zIndex:"1000", padding: window.innerWidth > 800 ? "0 10%" : '0 1%',minHeight: "10vh"}}>
                            <Header />
                        </header>
                        <main className="" style={{ width: "100%", padding: window.innerWidth > 800 ? "0 10%" : '0 1%',minHeight: "80vh"}}>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/search" component={Landing} />
                            <Route exact path="/newlisting" component={ListingNew} />
                            <Route exact path="/mylistings" component={MyListings} />
                            <Route exact path="/editlisting" component={EditListingNew} />
                            <Route exact path="/messages" component={Messages} />
                            <Route exact path="/attributions" component={Attributions} />
                            <Route exact path="/thankyou" component={Signed} />
                            <Route exact path="/faq" component={FAQ} />
                            <Route exact path="/tou" component={TOU} />
                            <Route exact path="/cookies" component={Cookies} />
                            <Route exact path="/privacy" component={Privacy} />
                            <Route exact path="/viewcontract" component={ViewContract} />
                        </main>
                        <footer style={{width: "100%"}}>
                            <Footer style={{alignSelf: "flex-end"}}/>
                        </footer>
                    </div>
                </BrowserRouter>
            </div>
        )
    }
}



export default connect(null, actions)(App);