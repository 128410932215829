import axios from 'axios';
import { FETCH_USER,FETCH_ACTIVE_LISTINGS,SET_PHOTO_INFO,FETCH_ACCOUNT_LISTINGS,TOGGLE_ACTIVE, FETCH_MESSAGES } from './types';

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user')
    dispatch({type: FETCH_USER, payload: res.data})
}

export const fetchActiveListings = (values) => async dispatch => {
    const res = await axios.post('/api/get_listings',values)
    dispatch({type: FETCH_ACTIVE_LISTINGS, payload: res.data})
}

export const fetchMessages = () => async dispatch => {
    const res = await axios.get('/api/get_messages')
    dispatch({type: FETCH_MESSAGES, payload: res.data})
}

export const deleteMessage = (values) => async dispatch => {
    const res = await axios.post('/api/delete_message',values)
}

export const sendContract = (values) => async dispatch => {
    const res = await axios.post('/api/send_contract',values)
}

export const fetchAccountListings = (values) => async dispatch => {
    const res = await axios.post('/api/get_account_listings',values)
    dispatch({type: FETCH_ACCOUNT_LISTINGS, payload: res.data})
}

export const submitListing =  (values,history) => async dispatch => {
    const res = await axios.post('/api/add_listing',values)
    history.push('/mylistings')
    // dispatch({type: ADD_POSITION, payload: res.data})
}

export const editListing =  (values,history) => async dispatch => {
    const res = await axios.post('/api/edit_listing',values)
    history.push('/mylistings')
    // dispatch({type: ADD_POSITION, payload: res.data})
}

export const deleteListing = async (values,history) => {
    const res = await axios.post('/api/delete_listing',values)
    // history.push('/mylistings')
    // dispatch({type: ADD_POSITION, payload: res.data})
}

export const sendEmail = async (values) => {
    const res = await axios.post('/api/send_email',values)
    // history.push('/mylistings')
    // dispatch({type: ADD_POSITION, payload: res.data})
}

export const toggleActive =  (values) => async dispatch => {
    const res = await axios.post('/api/toggle_active',values)
}

export const setPhotoInfo = (data) => async dispatch => {
    dispatch({type: SET_PHOTO_INFO, payload: data})
}