import React, {useEffect, useState, Fragment} from "react";
import {connect} from 'react-redux';
import { fetchMessages, deleteMessage, sendContract } from "../actions";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { ListItemButton } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';
import dog from './Images/dog1.png'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(130,190,170)',
    },
    secondary: {
      main: 'rgb(189,232,205)',
    }
  },
  typography: {

    button: { // Here is where you can customise the button
      fontWeight: 900,
    },    
  },  
});

function ChildModal(props) {
  const [open, setOpen] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [emailsent, setEmailsent] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    event.target.blur()
  };
  const handleClose2 = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    // console.log(props)
  },[])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // console.log('close')
    setOpen(false);
    // console.log(open)
  };

  const viewContract = (msg,fns) => {
    // console.log('View message: ',msg)
  }

  const sendContractModal = async (msg,fns) => {
    // console.log(msg)
    // console.log(fns)
    await fns.sendContract(msg)
    await fns.fetchMessages()
    // console.log('send contract')
    setAnchorEl(null);
    setOpen4(true)
  }

  const deleteMsg = async (msg,fns) => {
    // console.log('delete msg')
    await fns.deleteMessage({msgID: msg.messageID,msgOwner: msg.interestedListingOwnerID})
    await fns.fetchMessages()
    setAnchorEl(null);
    setOpen3(false)
  }

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:  window.innerWidth < 600 ? '90%':'50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  let tmp = {}
  let tmp2 = {}

    if (window.innerWidth > 800) {
      const d1 = new Date(props.params.contractStart)
      const d2 = new Date(props.params.contractEnd)
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(Math.abs((d1.setHours(0,0,0) - d2.setHours(0,0,0)) / oneDay));
      let expired = false
      let x = new Date(Date.now())
      if (d2.setHours(0,0,0) < x.setHours(0,0,0)) {
        expired = true
        console.log('expired: ',expired)
      }
      tmp = (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            {props.params.contractSigned 
              ? <Button disabled style={{marginRight: '15px'}} variant="contained" sx={{border: expired ? '1px solid red' : '1px solid blue', backgroundColor: 'white !important', color: 'black !important', textTransform: 'none'}} color="secondary" target="_blank" href={props.params.contractURL}>{expired ? `Contract EXPIRED.` : `Contract expires in ${diffDays} days.`}</Button> 
              : props.params.contractSent
                  ? <Button disabled style={{marginRight: '15px'}} variant="contained" sx={{border: expired ? '1px solid red' : '1px solid rgb(189,232,205)', backgroundColor: 'white !important', color: 'black !important', textTransform: 'none'}} color="secondary" target="_blank" href={props.params.contractURL}>Contract Sent</Button> 
                  : null }
            {!props.params.contractSigned ? 
            <div>
            <Button onClick={handleOpen3} variant="outlined" color="error" style={{marginRight: '15px'}}>Delete</Button>
            <Modal
              open={open3}
              onClose={handleClose3}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center', paddingBottom: '15px'}}>
                  Are you sure you want to delete this message?
                </Typography>
                <Button variant="outlined" color="primary" onClick={handleClose3}>No</Button>
                <Button color="error" variant="contained" onClick={()=>deleteMsg(props.params,props.fns)} style={{float: 'right'}}>Yes</Button>
              </Box>
            </Modal>
            </div>
            : null}
          
          {
            props.params.contractSigned ? 
                                          expired ?
                                          <Button variant="contained" disabled={!props.auth?.hasContract} onClick={()=>sendContractModal(props.params,props.fns)}>Send Contract</Button> : 
                                          <Button variant="contained" disabled={!props.auth?.hasContract} color="secondary" target="_blank" href={props.params.contractURL}>View Contract</Button>
                                        : 
                                        // <Button variant="contained" onClick={()=>sendContractModal(props.params,props.fns)}>Send Contract</Button>
                                        
                                          <Button variant="contained" disabled={!props.auth?.hasContract} onClick={()=>sendContractModal(props.params,props.fns)}>{props.params.contractSent ? 'Send Again' : 'Send Contract'}</Button>
                                          
                                        
          }
          <Modal
            open={open4}
            onClose={handleClose4}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center', paddingBottom: '15px'}}>
                The contract has been sent. Please check your email and fill in the details of the contract. Once you have completed it, the tenant will be notified to sign the contract.
              </Typography>
              <Box style={{textAlign: 'center'}} >
              <Button variant="outlined" color="primary" onClick={handleClose4}>Close</Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )
      tmp2 = (
        <div></div>
      )
    } else {
      const d1 = new Date(props.params.contractStart)
      const d2 = new Date(props.params.contractEnd)
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(Math.abs((d1.setHours(0,0,0) - d2.setHours(0,0,0)) / oneDay));
      let expired = false
      let x = new Date(Date.now())
      if (d2.setHours(0,0,0) < x.setHours(0,0,0)) {
        expired = true
        console.log('expired: ',expired)
      }
      tmp2 = (
        <div style={{width: '100%'}}>
          {props.params.contractSigned 
              ? <Button disabled style={{marginRight: '15px', width: '100%'}} variant="contained" sx={{border: expired ? '1px solid red' : '1px solid blue', backgroundColor: 'white !important', color: 'black !important', textTransform: 'none'}} color="secondary" target="_blank" href={props.params.contractURL}>{expired ? `Contract EXPIRED.` : `Contract expires in ${diffDays} days.`}</Button> 
              : props.params.contractSent
                  ? <Button disabled style={{marginRight: '15px', width: '100%'}} variant="contained" sx={{border: expired ? '1px solid red' : '1px solid rgb(189,232,205)', backgroundColor: 'white !important', color: 'black !important', textTransform: 'none'}} color="secondary" target="_blank" href={props.params.contractURL}>Contract Sent</Button> 
                  : null }
        </div>
      )
      tmp = (
        <div>
          <Button
            id="basic-button"
            aria-controls={open2 ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? 'true' : undefined}
            onClick={handleClick2}
            style={{fontSize: '18px', width: '20px', minWidth: '20px'}}
          >
            &#8230;
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open2}
            disableScrollLock={ true }
            onClose={handleClose2}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {/* <MenuItem onClick={()=>deleteMsg(props.params,props.fns)}>Delete</MenuItem> */}
            {!props.params.contractSigned ? <MenuItem>
              <Typography onClick={handleOpen3} style={{color: 'inherit'}}>Delete</Typography>
              <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center', paddingBottom: '15px'}}>
                    Are you sure you want to delete this message?
                  </Typography>
                  <Button variant="outlined" color="primary" onClick={handleClose3}>No</Button>
                  <Button color="error" variant="contained" onClick={()=>deleteMsg(props.params,props.fns)} style={{float: 'right'}}>Yes</Button>
                </Box>
              </Modal>
            </MenuItem> : null}
            {
            props.params.contractSigned ? 
                                          expired ?
                                              <MenuItem color="secondary" onClick={handleClose2}>
                                                <button disabled={!props.auth?.hasContract} onClick={()=>sendContractModal(props.params,props.fns)}>Send Contract</button>
                                              </MenuItem>
                                                  :
                                              <MenuItem color="secondary" onClick={handleClose2}>
                                                <a target="_blank" href={props.params.contractURL} style={{color: 'inherit', pointerEvents: !props.auth?.hasContract ? 'none' : 'auto' }}>View Contract</a>
                                              </MenuItem>
                                        : <MenuItem disabled={!props.auth?.hasContract} onClick={()=>sendContractModal(props.params,props.fns)}>{props.params.contractSent ? 'Send Again' : 'Send Contract'}</MenuItem>
            }

          </Menu>
          <Modal
            open={open4}
            onClose={handleClose4}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center', paddingBottom: '15px'}}>
                The contract has been sent.
              </Typography>
              <Box style={{textAlign: 'center'}} >
              <Button variant="outlined" color="primary" onClick={handleClose4}>Close</Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )
    }
  

  return (
    <Fragment>
      {/* <Button id={`showdetailbtn-${props.params.messageID}`} style={{display: "none"}}  variant='contained' onClick={handleOpen}>Contact Owner</Button> */}
      {/* <a id="showdetailbtn" style={{display: "none"}} className="black-text" onClick={handleOpen} href="#"></a> */}
      
      <ListItem button  alignItems="flex-start" style={{alignItems: 'center'}} onClick={handleOpen} >
                  <ListItemAvatar>
                      <Avatar  style={{width: '90px', height: '100px', marginRight: '10px'}} sx={{ bgcolor: 'white', color: 'black' }} alt="Not Available"  variant='square' src={props.params.interestedListingPhoto} >NA</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                  primary={toTitleCase(props.params.interestedListingAddress)}
                  primaryTypographyProps={{fontSize: '18px', fontWeight: '700 !important'}} 
                  secondary={
                      <React.Fragment>
                      <Typography
                          sx={{
                            display: "inline",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          style={{paddingRight: '5px'}}
                      >
                        Date: {new Date(Date.parse(props.params.messageTimestamp)).toLocaleString("en-US",{day:"numeric",hour: "numeric",minute: "numeric",month: "long",weekday: "long",year: "numeric"})}
                        </Typography>
                        <Typography
                          sx={{
                            display: "inline",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            textTransform: 'capitalize'
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          style={{paddingRight: '5px'}}
                      >
                        From: {props.params.interestedName}
                      </Typography>
                      {props.params.interestedListingMessage.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase())}
                      </React.Fragment>
                  }
                  secondaryTypographyProps={{fontSize: '14px'}} 
                  />
                  <ListItemSecondaryAction>
                      {tmp}
                  </ListItemSecondaryAction>
              </ListItem>
              <div style={{width: '100%', marginBottom: window.innerWidth > 800 ? 0 : '5px'}}>{tmp2}</div>
              <Divider />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        disableScrollLock={ true }
      >
        <Box sx={{ ...style, width: window.innerWidth < 600 ? '90%':'30%' }}>
          <div >
            <h6 id="child-modal-title" style={{paddingBottom: '20px'}}>Message Details</h6>
            <div id="child-modal-description">
              <div style={{paddingBottom: '10px'}}>
              <span>From: </span><span style={{textTransform: 'capitalize'}}>{props.params.interestedName}</span>
              </div>
              <div style={{paddingBottom: '10px'}}>
              <span>Date: </span><span>{new Date(Date.parse(props.params.messageTimestamp)).toLocaleString("en-US",{day:"numeric",hour: "numeric",minute: "numeric",month: "long",weekday: "long",year: "numeric"})}</span>
              </div>
              <div>
              <span>Message: </span><span>{props.params.interestedListingMessage.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase())}</span>
              </div>
            </div>
            <div style={{paddingTop: '10px', textAlign: 'center', display: 'flex', flexDirection: 'row'}}>
            <Button  onClick={handleClose} style={{marginRight: 'auto'}}>Close</Button>
            {props.params.contractSigned ? <Button disabled={!props.auth?.hasContract} style={{display: 'inline-block'}} variant="contained" color="secondary" target="_blank" href={props.params.contractURL}>View Contract</Button> : <Button disabled={!props.auth?.hasContract} onClick={()=>sendContractModal(props.params,props.fns)} variant="contained" style={{display: 'inline-block'}}>Send Contract</Button>}
            </div>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}

const Messages = ({fetchMessages, deleteMessage, sendContract, myMessages, auth}) => {
  const [msgs, setMsgs] = useState([]);
  const [tmpmsg, setTmpmsg] = useState([]);

    useEffect(()=>{
        fetchMessages()
      },[])

    useEffect(()=>{
        let tmp2 = myMessages
        tmp2.sort(function(x, y){
          return new Date(y.messageTimestamp) - new Date(x.messageTimestamp);
        })
        const tmp = tmp2.map((message,index) => {
          return (
              // <ListItem button key={`${index}`} divider alignItems="flex-start" style={{alignItems: 'center'}} onClick={()=>btnclk(message)} >
              //     <ListItemAvatar>
              //         <Avatar  style={{width: '100px', height: '100px', paddingRight: '10px'}} alt="Travis Howard"  variant='square' src={message.interestedListingPhoto} />
              //     </ListItemAvatar>
              //     <ListItemText
              //     primary={toTitleCase(message.interestedListingAddress)}
              //     primaryTypographyProps={{fontSize: '18px', fontWeight: '700 !important'}} 
              //     secondary={
              //         <React.Fragment>
              //         <Typography
              //             sx={{ display: 'inline'}}
              //             component="span"
              //             variant="body2"
              //             color="text.primary"
              //             style={{paddingRight: '5px'}}
              //         >
              //           From {message.interestedName} - 
              //         </Typography>
              //         {message.interestedListingMessage}
              //         </React.Fragment>
              //     }
              //     secondaryTypographyProps={{fontSize: '14px'}} 
              //     />
                  <ChildModal key={`${index}`} params={message} auth={auth} fns={{deleteMessage,fetchMessages,sendContract}}/>
                  // <ListItemSecondaryAction>
                  //     <Button style={{marginRight: '15px'}} variant="outlined" color="error" onClick={()=>deleteMsg(message)}>Delete</Button>
                  //     <Button variant="contained" onClick={()=>sendContract(message)}>Send Contract</Button>
                  // </ListItemSecondaryAction>
              // </ListItem>
          )
      })
      setMsgs(tmp)
    },[tmpmsg])

    useEffect(()=>{
      setTmpmsg(myMessages)
      // console.log('trigger')
    },[myMessages])

    const btnclk = (msg) => {
        // console.log(`show details of messageID: ${msg.messageID}`)
        const test = document.getElementById(`showdetailbtn-${msg.messageID}`)
        test.click()
    }

    

    // const sendContract = (msg) => {
    //     console.log(`send contract for message: ${msg.messageID}`)
    // }

    const toTitleCase = (str) => {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
      }

      // const deleteMsg = async (msg) => {
      //   await deleteMessage({msgID: msg.messageID,msgOwner: msg.interestedListingOwnerID})
      //   await fetchMessages()
      // }


    return(
        <div>
            <ThemeProvider theme={theme}>
                {auth?.hasContract ? <div></div> : <Button variant="outlined" color="error" component={Link} to={'/viewcontract'} style={{height: '75px', width: '100%'}}>Buy a Standard Contract</Button>}
                <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
                    {
                      msgs.length > 0 
                      ?
                        msgs
                      :
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                          <div style={{height: '20vh'}}></div>
                          <div className='row'>
                              <div className="col" style={{marginLeft: 0, paddingLeft: 0}}>
                                  <img style={{width: '203px'}} src={dog}></img>
                              </div>
                              <div className="yours messages col" style={{marginBottom: '20px', width: '700px'}} >
                                  <div className="message last">
                                      <label style={{fontSize: '35px', fontFamily: 'Alatsi',color: 'rgb(130,190,170)', WebkitTextStroke: '1px black'}}>You don't have any messages yet.</label>
                                  </div>
                              </div>
                          </div> 
                          <div style={{height: '30vh'}}></div>
                      </div>
                    }
                </List>
            </ThemeProvider>
        </div>
    )
}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            myMessages: state.myMessages
           }
}

export default connect(mapStateToProps,{fetchMessages,deleteMessage,sendContract})(Messages);