import { useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import { setPhotoInfo } from '../actions';

const UploadWidget = ({setPhotoInfo}) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  const handleOpenClick = (event) => {
    event.preventDefault()
    widgetRef.current.open()
  }

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget({
      cloudName: process.env.REACT_APP_CLOUDNAME,
      uploadPreset: 'ParkPark'
    }, function(error, result) {
        if (result.event === 'success' ){
            setPhotoInfo({url: result.info.secure_url,public_id:result.info.public_id,asset_id:result.info.asset_id,signature:result.info.signature})
        }
    });
  }, []);

  return (
    <div>
      <button onClick={handleOpenClick}>
        Upload
      </button>
    </div>
  );

};

function mapStateToProps(state) {
    return {
            photo: state.photo
           }
}

export default connect(mapStateToProps,{setPhotoInfo})(UploadWidget);