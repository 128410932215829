import { combineReducers } from "redux";
import authReducer from "./authReducer";
import activeListingsReducer from "./activeListingsReducer";
import {reducer as reduxForm} from 'redux-form';
import photoReducer from "./photoReducer";
import accountListingsReducer from "./accountListingsReducer";
import messagesReducer from "./messagesReducer";

export default combineReducers({
    auth: authReducer,
    activeListings: activeListingsReducer,
    accountListings: accountListingsReducer,
    form: reduxForm,
    photo: photoReducer,
    myMessages: messagesReducer
})
