import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import ListingField from './ListingField';
import _ from 'lodash'
import {Link} from 'react-router-dom';
import UploadWidget from '../UploadWidget';
import {connect} from 'react-redux';
import { setPhotoInfo } from '../../actions';
import './ListForm.css'

const FIELDS = [
    // {label: "Photo", name: "photo"},
    {label: "Title", name: "title"},
    {label: "Address", name: "address"},
    {label: "City", name: "city"},
    {label: "State", name: "state"},
    {label: "Zipcode", name: "zipcode"},
    {label: "Description",name:"description"},
    {label: "Cost", name: "cost"},
    {label: "Electricity", name: "electricity"},
    {label: "Wifi", name: "wifi"},
    {label: "Bathrooms", name: "bathrooms"},
    {label: "Dumpster", name: "dumpster"},
    {label: "Deposit", name: 'deposit'}
    // {label: "Term Length", name: "termlength"}
]

class ListingForm extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(prevProps.photo)
        // console.log(this.props.photo)
        // console.log(prevState)
        // console.log(prevProps)
        if (prevProps.photo.public_id !== this.props.photo.public_id) {
            if (!!this.props.photo.public_id) {
                this.props.change('photoname',this.props.photo.public_id)
                // document.getElementById('photoname').value = this.props.photo.asset_id;
                // document.getElementById('photoname').touched = true;
            }
        }
    }

    componentWillUnmount() {
        this.props.setPhotoInfo({})
    }

    componentDidMount() {
        this.props.initialize({ termlength: '1', termlengthfrequency: 'day', termpayment: '1', termpaymentfrequency: 'days', electricity: 'no', wifi: 'no', bathrooms: 'no', dumpster: 'no'});
    }

    renderFields() {
        return _.map(FIELDS, ({label,name}) => {
            return <Field key={name} component={ListingField} type="text" label={label} name={name}/>
        })
    }

    render() {
        return (
            <div>
               
                <form onSubmit={this.props.handleSubmit(this.props.onListingSubmit)}>
                    <h6>Photo</h6>
                    <div>
                            {this.props.photo.url && <img src={this.props.photo.url} style={{width: '50%'}} alt="location thumbnail" />}
                    </div>
                    <div>
                        <UploadWidget />
                    </div>
                    <div>
                        <Field name="photoname" type="text" component="input" disabled={true} />
                    </div>
                    {/* {this.renderFields()} */}

                    <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                        <h6 style={{paddingRight: '10px'}}>Title: </h6>
                        <div style={{width: '100%'}}>
                            <Field  component='input' type="text" label='Title' name='title'/>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                        <h6 style={{paddingRight: '10px'}}>Address:</h6>
                        <div style={{width: '100%'}}>
                            <Field  component='input' type="text" label='Address' name='address'/>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                        <h6 style={{paddingRight: '10px'}}>City:</h6>
                        <div>
                            <Field  component='input' type="text" label='City' name='city'/>
                        </div>
                        <h6 style={{paddingLeft: '30px', paddingRight: '10px'}}>State:</h6>
                        <div>
                        <Field name="state" component="select" style={{display: "initial"}}>
                            <option value='AK'>AK</option>
                            <option value='AZ'>AZ</option>
                            <option value='AR'>AR</option>
                            <option value='CA'>CA</option>
                            <option value='CO'>CO</option>
                            <option value='CT'>CT</option>
                            <option value='DE'>DE</option>
                            <option value='DC'>DC</option>
                            <option value='FL'>FL</option>
                            <option value='GA'>GA</option>
                            <option value='HI'>HI</option>
                            <option value='ID'>ID</option>
                            <option value='IL'>IL</option>
                            <option value='IN'>IN</option>
                            <option value='IA'>IA</option>
                            <option value='KS'>KS</option>
                            <option value='KY'>KY</option>
                            <option value='LA'>LA</option>
                            <option value='ME'>ME</option>
                            <option value='MD'>MD</option>
                            <option value='MA'>MA</option>
                            <option value='MI'>MI</option>
                            <option value='MN'>MN</option>
                            <option value='MS'>MS</option>
                            <option value='MO'>MO</option>
                            <option value='MT'>MT</option>
                            <option value='NE'>NE</option>
                            <option value='NV'>NV</option>
                            <option value='NH'>NH</option>
                            <option value='NJ'>NJ</option>
                            <option value='NM'>NM</option>
                            <option value='NY'>NY</option>
                            <option value='NC'>NC</option>
                            <option value='ND'>ND</option>
                            <option value='OH'>OH</option>
                            <option value='OK'>OK</option>
                            <option value='OR'>OR</option>
                            <option value='PA'>PA</option>
                            <option value='PR'>PR</option>
                            <option value='RT'>RI</option>
                            <option value='SC'>SC</option>
                            <option value='SD'>SD</option>
                            <option value='TN'>TN</option>
                            <option value='TX'>TX</option>
                            <option value='UT'>UT</option>
                            <option value='VT'>VT</option>
                            <option value='VA'>VA</option>
                            <option value='WA'>WA</option>
                            <option value='WV'>WV</option>
                            <option value='WI'>WI</option>
                            <option value='WY'>WY</option>
                            </Field>
                        </div>
                        <h6 style={{paddingLeft: '30px', paddingRight: '10px'}}>Zipcode:</h6>
                        <div>
                            <Field  component='input' type="text" label='Zipcode' name='zipcode'/>
                        </div>
                    </div>

                    

                    <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                        <h6 >Looking for a </h6>
                        <div style={{ paddingLeft: '10px'}}>
                            <Field name="termlength" component="select" style={{display: "initial"}}>
                                <option value='1'>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </Field>
                        </div>
                        <div style={{ paddingRight: '10px'}}>
                            <Field name="termlengthfrequency" component="select" style={{display: "initial"}}>
                                <option value='day'>day</option>
                                <option value="week">week</option>
                                <option value="month">month</option>
                                <option value="year">year</option>
                            </Field>
                        </div>
                        <h6 style={{paddingRight: '10px'}}>commitment with</h6>
                        <h6>$</h6>
                        <div>
                            {/* <Field  component='input' type="text" label='Cost' name='cost' /> */}
                            <Field component={ListingField} type="text" label='Cost' name='cost'/>
                        </div>
                        <h6 style={{paddingLeft: '10px'}}> rent to be paid every</h6>
                        <div style={{ paddingLeft: '10px'}}>
                            <Field name="termpayment" component="select" style={{display: "initial"}}>
                                <option value='1'>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </Field>
                        </div>
                        <div style={{ paddingRight: '10px'}}>
                            <Field name="termpaymentfrequency" component="select" style={{display: "initial"}}>
                                <option value='days'>day(s)</option>
                                <option value="weeks">week(s)</option>
                                <option value="months">month(s)</option>
                                <option value="years">year(s)</option>
                            </Field>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                        <h6>Deposit: $</h6>
                        <div>
                            {/* <Field  component='input' type="text" label='Deposit' name='deposit' style={{width: '200px'}}/> */}
                            <Field component={ListingField} type="text" label='Deposit' name='deposit' style={{width: '200px'}}/>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                        <h6 style={{margin: '0px'}}>Will you be providing electricity?</h6>
                        <div >
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="electricity" component="input" type="radio" value="yes" /><span style={{color: 'black'}}>Yes</span></label>
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="electricity" component="input" type="radio" value="no"  /><span style={{color: 'black'}}>No</span></label>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                        <h6 style={{margin: '0px'}}>Will you be providing wifi?</h6>
                        <div >
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="wifi" component="input" type="radio" value="yes" /><span style={{color: 'black'}}>Yes</span></label>
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="wifi" component="input" type="radio" value="no"  /><span style={{color: 'black'}}>No</span></label>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                        <h6 style={{margin: '0px'}}>Will you be providing bathrooms?</h6>
                        <div >
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="bathrooms" component="input" type="radio" value="yes" /><span style={{color: 'black'}}>Yes</span></label>
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="bathrooms" component="input" type="radio" value="no"  /><span style={{color: 'black'}}>No</span></label>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                        <h6 style={{margin: '0px'}}>Will you allow usage of a dumpster?</h6>
                        <div >
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="dumpster" component="input" type="radio" value="yes" /><span style={{color: 'black'}}>Yes</span></label>
                            <label style={{paddingLeft: '15px'}}><Field className="with-gap" name="dumpster" component="input" type="radio" value="no"  /><span style={{color: 'black'}}>No</span></label>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                        <h6 style={{paddingRight: '10px'}}>Description: </h6>
                        <div style={{width: '100%'}}>
                            <Field  component='textarea' type="text" label='Description' name='description'/>
                        </div>
                    </div>

                    {/* <div>
                        <label>Select Term Length</label>
                        <div>
                            <Field name="termlength" component="select" style={{display: "initial"}}>
                                <option value=''></option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </Field>
                        </div>
                    </div> */}
                    <div style={{paddingBottom: '20px'}}>
                    <Link to='/mylistings' className="red btn-flat white-text">Cancel<i className="material-icons right">clear</i></Link>
                    <button type="submit" className="btn-flat right white-text" style={{backgroundColor: 'rgb(130,190,170)'}}>Submit<i className="material-icons right">done</i></button>
                    </div>
                </form>
                <div style={{height: '30vh'}}>
                      </div>
            </div>
        )
    }
}

function validate(values){
    const errors = {}
    // if (!values.photoname) {
    //     errors.photo = "You must provide a photo"
    // }
    // if (!values.title) {
    //     errors.title = "You must provide a title"
    // }
    // if (!values.address) {
    //     errors.address = "You must provide an address"
    // }
    // if (!values.city) {
    //     errors.city = "You must provide a city"
    // }
    // if (!values.description) {
    //     errors.description = "You must provide a description"
    // }
    if (isNaN(parseFloat(values.cost))) {
        errors.cost = "You must provide a numeric cost"
    }
    if (isNaN(parseFloat(values.deposit))) {
        errors.deposit = "You must provide a numeric deposit"
    }
    // if (isNaN(parseFloat(values.zipcode))) {
    //     errors.zipcode = "You must provide a numeric zipcode"
    // }
    // if (typeof values.termlength === 'undefined') {
    //     errors.termlength = "You must select the term length (daily, weekly, monthly, annualy)"
    // }
    return errors
}

function mapStateToProps(state) {
    return {
            photo: state.photo
           }
}

export default reduxForm({
    validate,
    form: 'listingForm',
})(connect(mapStateToProps,{setPhotoInfo})(ListingForm));