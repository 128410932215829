import contract from './draft_agreement.pdf'
import {connect} from 'react-redux';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

const ViewContract = ({auth}) => {

    return(
        <div>
            
            {auth?.hasContract ? 
            <div></div> 
            : 
            <div>
                
                <form action="/api/create-checkout-session5" method="POST">
                    <Button type='submit' variant="outlined" color="error" style={{height: '75px', width: '100%', marginBottom: '20px'}}>Buy This Contract</Button>
                </form>
            </div>
            }
            <div className="embed-responsive" style={{ height: "100vh" }}>
                <embed src={contract} type="application/pdf" width="100%" height="100%" view='Fit' />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
            auth: state.auth
           }
}

export default connect(mapStateToProps,{})(ViewContract);