import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import { useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
// const keys = require('../../../config/keys');
import {setKey,setDefaults,setLanguage,setRegion,fromAddress,fromLatLng,fromPlaceId,setLocationType,geocode,RequestType} from 'react-geocode'
import {fetchActiveListings, sendEmail} from '../actions';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser'
import { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import searchIcon from './icons/magnifying-glass.png'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Landing.css'
import Bolt from '@mui/icons-material/Bolt';
import Delete from '@mui/icons-material/Delete';
import Wifi from '@mui/icons-material/Wifi';
import Wc from '@mui/icons-material/Wc';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(130,190,170)',
    },
  },
  typography: {

    button: { // Here is where you can customise the button
      fontWeight: 900,
    },    
  },  
});


// const sendEmailFn = sendEmail

function ChildModal(props) {
  const [open, setOpen] = React.useState(false);
  const [emailsent, setEmailsent] = React.useState(false);

  // useEffect(()=>{
  //   // console.log(activeListings)
  //   console.log(props.currentUser)
  // },[])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen(false);
    setEmailsent(false);
  }
  const contactOwner = () => {
    const tmp = document.getElementById('outlined-multiline-static')
    // sendEmailFn({params: params.params, message: tmp.value})
    // console.log('user')
    // console.log(props.currentUser)
    // console.log(props.params)
    const serviceID = 'service_z4ydjwh'
    const templateID = 'template_4mdedc8'
    const templateParams = {
      to_name: props.params.owner,
      from_name: props.currentUser,
      to_email: props.params.email,
      message: tmp.value,
      address: props.params.address
    }
    const publicKey = 'yTCIAtaO519kDhcHB'

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then(function(response) {
        // console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
        console.log('FAILED...', error);
      });
    
    setEmailsent(true);


    const message_details = {
      interestedID: props.currentUserID,
      interestedName: props.currentUser,
      interestedEmail: props.currentEmail,
      interestedListingID: props.params._id,
      interestedListingAddress: props.params.address,
      interestedListingPhoto: props.params.photo,
      interestedListingOwnerID: props.params._user,
      interestedListingMessage: templateParams.message,
      interestedListingOwnerEmail: props.params.email,
      interestedListingOwnerName: props.params.owner,
      messageID: uuidv4(),
      messageTimestamp: getDateTime(),
      constractSent: false,
      constractSigned: false,
      contractURL: '',
      contractStart: '',
      contractEnd: ''
    }

    sendEmail(message_details)
    // console.log(props)

  }

  function getDateTime() {
    var now     = new Date(); 
    var year    = now.getFullYear();
    var month   = now.getMonth()+1; 
    var day     = now.getDate();
    var hour    = now.getHours();
    var minute  = now.getMinutes();
    var second  = now.getSeconds(); 
    if(month.toString().length == 1) {
         month = '0'+month;
    }
    if(day.toString().length == 1) {
         day = '0'+day;
    }   
    if(hour.toString().length == 1) {
         hour = '0'+hour;
    }
    if(minute.toString().length == 1) {
         minute = '0'+minute;
    }
    if(second.toString().length == 1) {
         second = '0'+second;
    }   
    var dateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;   
     return dateTime;
    } 

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:  window.innerWidth < 600 ? '90%':'50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Fragment>
      <div className='center-align' style={{paddingTop: '10px'}}>
      {
        props.currentUser
        ?
        <Button variant='contained' onClick={handleOpen}>Contact Owner</Button>
        :
        <Button variant='contained' href='auth/google'>Sign In To Contact Owner</Button>
      }
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: window.innerWidth < 600 ? '90%':'30%' }}>
          { emailsent 
          ? <div className='center-align'>
              <h5>Thank you, your message has been sent.</h5>
              <Button variant='contained' onClick={handleClose2}>OK</Button>
            </div>
          :
          <div>
            <h6 id="child-modal-title">Include a personal message to the owner:</h6>
            <div style={{paddingBottom: '13px'}}>
              <label className="black-text"><b>Please note, the owner does not have your contact information, please include a method for the owner to contact you.</b></label>
            </div>
            <div id="child-modal-description">
            <TextField
              id="outlined-multiline-static"
              label="Message"
              style={{width: '100%'}}
              multiline
              rows={4}
            />
            </div>
            <div style={{paddingTop: '10px'}}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={contactOwner} variant="contained" style={{float: 'right'}}>Send</Button>
            </div>
          </div>
          }
        </Box>
      </Modal>
    </Fragment>
  );
}

const Landing = ({activeListings,fetchActiveListings,sendEmail, location, auth}) => {
    const [lat, setLat] = useState(39.7392358);
    const [lng, setLng] = useState(-104.990251);
    const [maplat, setMaplat] = useState(39.7392358);
    const [maplng, setMaplng] = useState(-104.990251)
    const [zipcode, setZipcode] = useState(80202)
    const [searchLocation, setSearchLocation] = useState('Denver')
    const [vismarkers, setVismarkers] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [modalCost, setModalCost] = useState(0)
    const [modalDescription, setModalDescription] = useState('')
    const [modalTermLength, setModalTermLength] = useState('')
    const [modalDeposit, setModalDeposit] = useState('')
    const [modalTermPayment, setModalTermPayment] = useState('')
    const [modalTermPaymentFreq,setModalTermPaymentFreq] = useState('')
    const [modalTermLengthFreq, setModalTermLengthFreq] = useState('')
    const [modalAddress, setModalAddress] = useState('');
    const [modalEmail, setModalEmail] = useState('')
    const [modalPhoto, setModalPhoto] = useState('')


    // const position = { lat: lat, lng: lng };
    const [position, setPosition] = useState({ lat: 39.7392358, lng: -104.990251 }) 
    const [open, setOpen] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);

    const cloudName = process.env.REACT_APP_CLOUDNAME

    useEffect(()=>{
      // console.log(lat)
      // console.log(lng)
      setPosition({ lat: lat, lng: lng })
    },[lat,lng])

    useEffect(()=>{


      setSearchLocation(location.state.item)

      setDefaults({
        key: process.env.REACT_APP_GMAP_KEY,
        language: 'en',
        region: 'es'
      })
    
      fromAddress(location.state.item)
        .then(({results}) => {
          // console.log(results)
          const {lat,lng} = results[0].geometry.location;
          setLat(lat)
          setLng(lng)
          setMaplat(lat)
          setMaplng(lng)
        }).catch(console.error)

      // fromLatLng(39.7392358, -104.990251)
      //   .then(({ results }) => {
      //     const { lat, lng } = results[0].geometry.location;
      //     const x = _.find(results[0].address_components, function (ac) { return ac.types[0] === 'postal_code' }).short_name
      //     setZipcode(x)
      //   })
      //   .catch(console.error);

        const getListings = async () => {
          const tmp = await fetchActiveListings({lat:maplat,lng:maplng})
        }
        getListings()
        // console.log('test')
        
        // console.log(activeListings)
        // console.log(listings)

    },[])

    useEffect(()=>{
      // console.log(activeListings)
      setVismarkers(activeListings)
    },[activeListings])

    useEffect(()=>{
      fromAddress(searchLocation)
        .then(({results}) => {
          const {lat,lng} = results[0].geometry.location;
          setLat(lat)
          setLng(lng)
        }).catch(console.error)
    },[searchLocation])

    const handleSubmit = (event) => {
      event.preventDefault()
      setSearchLocation(event.target.searchLocation.value)
      event.target.searchLocation.value=''
    }

    const centerMoved = (event) => {
      // console.log(event.map.center.lat())
      // console.log(event.map.center.lng())
      setMaplat(event.map.center.lat())
      setMaplng(event.map.center.lng())
    }

    useEffect(()=> {
      const getListings = async () => {
        const tmp = await fetchActiveListings({lat:maplat,lng:maplng})
      }
      getListings()
      // fetchActiveListings({lat:maplat,lng:maplng})
      
    },[maplng])

    const showDetails = (item) => {
      setActiveMarker(null)
      // console.log(item)
      setModalTitle(item.title)
      setOpen(true)
      // console.log('done')
      // show modal with listing info on top of map
    }

    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
      // console.log(marker)
      const y = activeListings.find(x => x._id === marker)
      // console.log(y)
      let words = y.title.split(" ");
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      const title = words.join(" ");
      setModalTitle(title)


      setModalCost(y.cost)
      setModalDescription(y.description)
      setModalTermLength(y.termlength)
      setModalTermPayment(y.termpayment)
      setModalTermPaymentFreq(y.termpaymentfrequency)
      setModalTermLengthFreq(y.termlengthfrequency)
      setModalDeposit(y.deposit)
      words = y.address.split(" ");
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      const addr = words.join(" ");
      setModalAddress(addr)
      setModalEmail(y.email)
      setModalPhoto(y.photo.replace('1000/','330/'))


    };

    const handleOpen = () => {
      // setModalTitle(item.title)
      setOpen(true)
    };
    const handleClose = () => {
      setActiveMarker(null)
      setOpen(false);
    }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: window.innerWidth > 800 ? 400 : window.innerWidth*0.92,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      paddingLeft: '32',
      paddingRight: '32',
      paddingBottom: '32',
      paddingTop: '0'
    };

    // const sendEmail = (event,item) => {
      // event.preventDefault()
      // console.log('send email')
      // console.log(item)
      // const serviceID = 'service_d6pv7xa'
      // const templateID = 'template_4mdedc8'
      // const templateParams = {
      //   to_name: 'John',
      //   from_name: 'Me',
      //   to_email: 'admin@ssstats.com',
      //   from_email: 'ibrahim.krad@gmail.com',
      //   message: 'hello world'
      // }
      // const publicKey = 'yTCIAtaO519kDhcHB'

      // emailjs.send(serviceID, templateID, templateParams, publicKey)
      //   .then(function(response) {
      //     console.log('SUCCESS!', response.status, response.text);
      //   }, function(error) {
      //     console.log('FAILED...', error);
      //   });
    // }

    const mapMarkers = vismarkers.map((item,index) => {
      const iconcheck = [item.electricity, item.bathrooms, item.wifi, item.dumpster]
      const iconref = [<Bolt style={{paddingLeft: '4px',paddingRight: '4px' }} />,<Wc style={{paddingLeft: '4px',paddingRight: '4px' }} />,<Wifi style={{paddingLeft: '4px',paddingRight: '4px' }} />,<Delete style={{paddingLeft: '4px',paddingRight: '4px' }} />]
      const tooltiptexts = ['Owner will provide electrical connections.','Owner will provide bathroom access.','Owner will provide wifi.','Owner will provide dumpster access.']
      let icns = []
      const icnsdisp = [...Array(4)].map((_, i) => {
        if (iconcheck[i]===true && icns.length===0) {
          icns.push(
          <Tooltip key={`${item.title}-${i}`} title={tooltiptexts[i]}>
            <div>
              {iconref[i]}
            </div>
          </Tooltip>
          )
        } else if (iconcheck[i]===true && icns.length > 0) {
          // icns.push(<h6>|</h6>)
          icns.push(<Divider key={`${item.title}-d${i}`} style={{ background: 'black'}} orientation="vertical" flexItem />)
          icns.push(
            <Tooltip key={`${item.title}-${i}`} title={tooltiptexts[i]}>
              <div>
                {iconref[i]}
              </div>
            </Tooltip>
            )
        }
        if (i === 3){
        return icns}
      });
      // console.log(icnsdisp)
      return (
        <div key={item._id}>
            <AdvancedMarker position={{ lat: item.lat, lng: item.lng }} onClick={() => handleActiveMarker(item._id)}>
              <Pin
                // background={'rgba(0,0,0,0)'}
                // borderColor={"rgba(0,0,0,0)"}
                // glyphColor={"rgba(242,38,19,1)"}
                // scale={2}
              />
            </AdvancedMarker>

            {activeMarker === item._id ? (
                <InfoWindow className='test' position={{ lat: item.lat, lng: item.lng }} onCloseClick={() => setActiveMarker(null)} style={{width: '200px', padding: '0px'}}>
                  {item.photo.search('undefined.') < 0 ? <img style={{width:"200px",margin : "0"}} src={item.photo.replace('1000/','200/')} alt="location thumbnail"/> : null}
                  <h6 style={{paddingLeft: '15px'}}>${item.cost}</h6>
                  <span style={{paddingLeft: '15px'}}>{item.address}</span>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '15px', marginTop: '5px', paddingBottom: '5px',paddingRight: '15px'}}>
                    {icnsdisp}
                    {/* <Button onClick={() => showDetails(item)}>Details</Button> */}
                    <Button variant='text' onClick={handleOpen} style={{marginLeft: 'auto', textTransform: 'none'}} sx={{ '&.MuiButton-root:focus':{bgcolor: 'transparent'} }}>Details</Button>
                  </div>
                  
                  <div className='center-align'>
                  <ThemeProvider theme={theme}>
                    {/* <div style={{paddingBottom: '10px'}}>
                    <Button variant='contained' onClick={handleOpen}>Details</Button>
                    </div> */}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box sx={style}>
                      <div style={{height: '32', display: 'flex', justifyContent: 'right'}}>
                        <IconButton aria-label="close details" edge="end" style={{minHeight: 32}} sx={{padding: 0, color: 'black'}} onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                      {modalPhoto.search('undefined.') < 0 ? <img style={{width: '100%'}} src={modalPhoto} alt="modal photo url" /> : null}
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalTitle}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalAddress}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalDescription.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase())}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Deposit: ${modalDeposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Owner is looking for a {modalTermLength} {modalTermLengthFreq} commitment. Rent is ${modalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} to be paid every {modalTermPayment} {modalTermPaymentFreq}.
                      </Typography>
                      <div id="modal-modal-description" style={{ marginTop: '16px' }}>
                      {icnsdisp[3].map((item,index) => {
                        return <div key={`${item.props.title}-${index}`}>{item.props.title}</div>
                      })}
                      </div>
                      <div>
                      {/* <button onClick={()=>sendEmail(event,item)}>Send</button> */}
                    </div>
                    
                      <ChildModal params={item} currentUser={auth.name} currentEmail={auth.email} currentUserID={auth._id}/>
                    
                    </Box>
                    </Modal>
                    </ThemeProvider>
                  </div>
              </InfoWindow>
            )  : null}

            
        </div>
      )
    })



    const renderedData = 
      
        (
              <div>
                <div style={{ padding: '20px'}}>
                  <form onSubmit={handleSubmit} >
                    {/* <input name='searchLocation' placeholder='Find a location...'/> */}
                    <div className='row valign-wrapper'>
                      { window.innerWidth > 600 ? <div className='col m2' style={{margin: 0}}>
                        <label></label>
                      </div> : <div /> }
                      
                      <div className='col s12 m7' style={{margin: 0}}>
                        <TextField
                          id="searchLocation"
                          label="Find a location"
                          style={{width: '100%'}}
                          variant='outlined'
                          inputProps={{style: { border: 'none', paddingLeft: '10px', boxShadow: 'none', WebkitBoxShadow: 'none' } }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="search for a location"
                              type='submit'
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>,
                          }}
                        />
                      </div>
                      {/* <div className='col s1 m1' style={{margin: 0}}>
                      <ThemeProvider theme={theme}>
                        <Button type='submit' variant="text" startIcon={<img style={{width: '30px', height: '30px'}} src={searchIcon} />}>Search</Button>
                      </ThemeProvider>
                      </div> */}
                    </div>
                  </form>
                </div>
                <div>
                    <APIProvider apiKey={process.env.REACT_APP_GMAP_KEY}>
                      <div style={{ height: "75vh", width: "100%", paddingBottom: '30px' }}>
                        <Map zoom={15} center={position} mapId={'e544a274cc0288af'} onDragend={centerMoved} onClick={() => setActiveMarker(null)}>
                          {mapMarkers}
                          {/* <AdvancedMarker position={position} onClick={() => setOpen(true)}>
                            <Pin
                              background={"grey"}
                              borderColor={"green"}
                              glyphColor={"purple"}
                            />
                          </AdvancedMarker>

                          {open && (
                            <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
                              <p>I'm in Hamburg</p>
                            </InfoWindow>
                          )} */}
                        </Map>
                      </div>
                    </APIProvider>
                </div>
              </div>
        )
    

    
        return (
            renderedData
        )
    
}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            form: state.form,
            activeListings: state.activeListings
           }
}

export default withRouter(connect(mapStateToProps,{fetchActiveListings,sendEmail})(Landing))