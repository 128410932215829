import {Link} from 'react-router-dom';

const Footer = () => {

    return(
        <footer className="page-footer" style={{backgroundColor: 'rgb(189,232,205)', alignSelf: "flex-end", paddingTop:"0px"}}>
          <div className="" style={{padding: "0 10%"}}>
            <div className="row">
              <div className="col s12">
                {/* <h2 className="white-text" style={{fontFamily: "Gruppo"}}>Footer</h2> */}
                <div style={{height: '3vh'}}></div>
                <Link to='/faq' className="white-text" style={{textDecorationLine: 'underline'}}>FAQ</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to='/tou' className="white-text" style={{textDecorationLine: 'underline'}}>Terms of Use</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to='/privacy' className="white-text" style={{textDecorationLine: 'underline'}}>Privacy Policy</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to='/cookies' className="white-text" style={{textDecorationLine: 'underline'}}>Cookies Policy</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to='/attributions' className="white-text" style={{textDecorationLine: 'underline'}}>Attributions</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                
                <a href="mailto:support@parkpark.io" className='white-text' style={{textDecorationLine: 'underline'}}>Contact Us</a>
                
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="" style={{width: "100%", padding: "0 10%"}}>
              <label className="right white-text">© ParkPark 2024</label>
            </div>
          </div>
        </footer>
    )
}


export default Footer;